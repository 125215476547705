// Import material theming functions

$mx-primary: #2E2E52;
$mx-accent: #669FAD;
$mx-accent-light: rgb(165, 200, 209);
$mx-accent-dark: rgb(77, 127, 139);
// $mx-primary: rgb(85, 85, 117);
$mx-primary-dark: #1E1E35;
$mx-primary-light: rgb(79, 79, 116);
$mx-primary-warning: red;

$mx-main-background: #F3F3F3;

$hover-white: #f5f5f5;

// primary font
$primary-font: 'Nunito';
// font-weight
$light: 300;
$regular: normal;
$bold: bold;
$semi-bold: 600;
$extra-bold: 800;
// Font size
$font-xs:12px;
$font-sm: 14px;
$primary-fs: 16px;
$font-md: 18px;
$font-lg: 24px;

$embedded-button-color-active: rgb(252, 252, 252);

// primeng table colors
$table-cell-text-color: #495057;
$table-header-background-color: #f8f9fa;
$table-header-border-color: #e9ecef;

// primeng family font
$mx-material-family-font: 'Roboto';
