@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "src/variables.scss";
@import './styles/material-theme';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
@import '~primeicons/primeicons.css';

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
  font-size: $primary-fs;
  font-weight: $light;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: $mx-main-background;
  // quick fix since we need a scrollbar in provider search result list.
  overflow: hidden;
  /* ::-webkit-scrollbar {
    display: none;
  } */
}

h2, h3, h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: $primary-font;
}

.mx-bar-title {
  font-family: $primary-font;
  font-weight: $semi-bold;
}

.mx-bar-max {
  font-weight: $bold;
}

.page-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.spinner-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.page-content {
  width: 100%;
  max-width: 1400px;
}

.utility-item {
  white-space: nowrap;
  color: $mx-primary !important;
  font-size: 12px;
  height: 35px !important;
}

.nav-sub-item {
  white-space: nowrap;
  font-size: $font-xs;
  font-weight: $light;
  color: #fff !important;
}

.subnav-item {
  font-weight: $light !important;
  white-space: nowrap;
}

.nav-spacer {
  height: 20px;
}




.site-sidenav-user {
  h1 {
    font-family: $primary-font;
    font-weight: $semi-bold;
    font-size: $font-md;
    white-space: nowrap;
  }
}

.header-less-tabs > .mat-tab-group > .mat-tab-header {
  display: none;
}

.action-link {
  text-decoration: underline;
  cursor: pointer;
}

.mx-page-title {
  font-size: $font-lg;
  font-weight: $bold;
  margin-bottom: 5px;
  padding-left: 16px;
}

.mx-card {
  padding: 20px;
  background-color: white;

  .mx-title:hover {
    cursor: pointer;
    color: $mx-primary-dark;
  }
}

.mx-title {
  color: $mx-primary-dark;
  font-size: $font-lg;
  font-weight: $bold;
  margin-bottom: 5px;
}

.mx-card-section {
  margin-top: 10px;
  width: 50%;
}

.mx-card-section-header {
  font-family: $primary-font;
  font-weight: $semi-bold;
}

.mx-section-sub-header {
  font-family: $primary-font;
  font-weight: $regular;
}

.mx-sub-title {
  font-family: $primary-font;
  font-weight: $extra-bold;
  font-size: $font-md;
  color: $mx-primary;
}

.mx-page-container
{
    height: 100%;
    padding: 20px;
}

.mx-scroll-panel .ui-scrollpanel-wrapper {
  border-right: 9px solid #f4f4f4;
}

// .mx-scroll-panel .ui-scrollpanel-bar {
//   background-color: $mx-primary;
//   opacity: 1;
//   transition: background-color .3s;
// }

// .mx-scroll-panel .ui-scrollpanel-bar:hover {
//   background-color: $mx-primary-dark;
// }

gridster {
  background: none !important;
}

.mx-widget-sm-card {
  height: 150px;
}

.mat-input-element {
  height: 20px;
}

.fill-space {
  flex: 1 1 auto;
}

.text-with-icon {
  display: flex;
  align-items: center;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-weight: bold;
}

.widget {
  display: flex;
  flex-flow: column;
  height: 100%;

  .flex-chart-container {
    flex-grow: 1;
  }
}

.embedded-button {
  transition: box-shadow 0.8s ease-out;
  box-shadow: 
    -2px -2px 1px 0 rgba(255, 255, 255, 1),
    -8px -8px 8px 0 rgba(255, 255, 255, .1),
    2px 2px 3px 0 rgba(0, 0, 0, .08),
    8px 8px 8px 0 rgba(0, 0, 0, .05);

  &.selected {
    border: 2px solid $mx-primary;
  }
}

.embedded-button:hover {
  cursor: pointer;
  box-shadow: 
    -1px -1px 1px 0 rgba(255, 255, 255, 1),
    2px 2px 3px 0 rgba(0, 0, 0, .1);
  transition: box-shadow 0.1s ease-out;
}

.embedded-button:active {
  background-color: $embedded-button-color-active;
  box-shadow: 
    inset -2px -2px 3px 0 rgba(255, 255, 255, .7),
    inset 2px 2px 3px 0 rgba(0, 0, 0, .1);
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background-color: #2196F3 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: none !important;
}
